import React from "react";
import Jumbo from "../components/Sections/Jumbo";
import app from "../App.json";
import StoreButton from "../components/Buttons/StoreButtons";

const Home = () => {
  const storeButton = <StoreButton />;

  return (
    <div>
      {/* Hero Section */}
      <Jumbo
        title={app.name}
        body="Discover a new way to express yourself, tell a story or share an idea and connect with the audio space."
        button={storeButton}
      />

      {/* Main Features */}
      {/* <Feature
        title="Discover JustTalk Features"
        body="Explore all the ways you can connect, share, and grow with JustTalk."
        items={app.contents.map((content, index) => (
          <Display
            key={index}
            image={`https://source.unsplash.com/random/600x400/?${index + 1}`}
            title={content.title}
            body={content.body}
          />
        ))}
        bg="bg-light"
        variant="text-dark"
      /> */}
    </div>
  );
};

export default Home;