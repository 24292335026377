// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headbar {
    background: rgba(0, 0, 0, 0.305);
    color: #fff;
    backdrop-filter: blur(30px) !important;
 }
 .bg-dark{
     background: rgba(0, 0, 0, 0.923) !important;
     color: #fff !important;
 }
 .bg-red{
     background: rgba(70, 0, 0, 0.017) !important;
     color: #fff !important;
 }

 .bg-naked{
     background: rgba(0, 0, 0, 0.438) !important;
 }
 .navbar-dark{
    background: rgba(14, 6, 0, 0.863) !important;
 }
 .btn-dark{
     background-color: rgb(27, 54, 54) !important;
 }

 .dropitem{
    padding: 20px !important;
 }


 .bg-darker{
    background: rgb(0, 0, 0) !important;
}

.bg-darker a{
   color:  #fff !important;
   text-decoration: none !important;
}





.navbar-toggler,.navbar-toggler-icon,.navbar-toggler polyline{
    stroke: #ffffff !important;
  }


.navbar-toggler svg {
    fill: #ffffff !important;
    stroke: #ffffff !important;
    color: #ffffff !important;
}







`, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,WAAW;IAEX,sCAAsC;CACzC;CACA;KACI,2CAA2C;KAC3C,sBAAsB;CAC1B;CACA;KACI,4CAA4C;KAC5C,sBAAsB;CAC1B;;CAEA;KACI,2CAA2C;CAC/C;CACA;IACG,4CAA4C;CAC/C;CACA;KACI,4CAA4C;CAChD;;CAEA;IACG,wBAAwB;CAC3B;;;CAGA;IACG,mCAAmC;AACvC;;AAEA;GACG,uBAAuB;GACvB,gCAAgC;AACnC;;;;;;AAMA;IACI,0BAA0B;EAC5B;;;AAGF;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,yBAAyB;AAC7B","sourcesContent":[".headbar {\n    background: rgba(0, 0, 0, 0.305);\n    color: #fff;\n    -webkit-backdrop-filter: blur(30px) !important;\n    backdrop-filter: blur(30px) !important;\n }\n .bg-dark{\n     background: rgba(0, 0, 0, 0.923) !important;\n     color: #fff !important;\n }\n .bg-red{\n     background: rgba(70, 0, 0, 0.017) !important;\n     color: #fff !important;\n }\n\n .bg-naked{\n     background: rgba(0, 0, 0, 0.438) !important;\n }\n .navbar-dark{\n    background: rgba(14, 6, 0, 0.863) !important;\n }\n .btn-dark{\n     background-color: rgb(27, 54, 54) !important;\n }\n\n .dropitem{\n    padding: 20px !important;\n }\n\n\n .bg-darker{\n    background: rgb(0, 0, 0) !important;\n}\n\n.bg-darker a{\n   color:  #fff !important;\n   text-decoration: none !important;\n}\n\n\n\n\n\n.navbar-toggler,.navbar-toggler-icon,.navbar-toggler polyline{\n    stroke: #ffffff !important;\n  }\n\n\n.navbar-toggler svg {\n    fill: #ffffff !important;\n    stroke: #ffffff !important;\n    color: #ffffff !important;\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
