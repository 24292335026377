import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../css/Header.css";
import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DropDown from "./Dropdown";
import { GrFacebook, GrInstagram, GrTwitter, GrDown } from "react-icons/gr";

const HeadBar = ({ icon, title, headerLinks, contacts }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="lg"
      className={`headbar ${scrolled ? "scrolled" : ""}`}
    >
      <Container fluid>
        <Row className="w-100 m-auto">
          <Col xs="auto" className="d-flex align-items-center">
            {/* Logo */}
            <Navbar.Brand as={Link} to="/">
              <img
                alt="logo"
                src={icon}
                style={{ height: 40 }}
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </Col>

          <Col xs="auto" className="d-flex align-items-center">
            {/* Title */}
            <Navbar.Text className="d-none d-lg-block">
              <b className="text-light">{title || "JustTalk"}</b>
            </Navbar.Text>
          </Col>

          <Col xs="auto" className="ms-auto d-flex align-items-center">
            {/* Mobile Menu Toggle */}
            {/* <Navbar.Toggle aria-controls="navbarScroll">
               <GrDown /> 
            </Navbar.Toggle> */}
          </Col>
        </Row>

        <Navbar.Collapse id="navbarScroll">
          <Row className="w-100 justify-content-center">
            <Col xs="auto">
              {/* Navigation Links */}
              <Nav className="me-auto">
                {headerLinks &&
                  headerLinks.map((link, i) =>
                    link.drop ? (
                      <DropDown key={i} name={link.title} list={link.drop} />
                    ) : (
                      <Nav.Link
                        as={Link}
                        to={link.path}
                        key={i}
                        className={({ isActive }) =>
                          isActive ? "link-active" : "link"
                        }
                      >
                        {link.title}
                      </Nav.Link>
                    )
                  )}
              </Nav>
            </Col>

            {contacts && (
              <Col xs="auto">
                {/* Social Media Links */}
                <Nav className="justify-content-end">
                  <Nav.Link
                    href={`https://facebook.com/${contacts.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GrFacebook />
                  </Nav.Link>

                  <Nav.Link
                    href={`https://twitter.com/${contacts.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GrTwitter />
                  </Nav.Link>

                  <Nav.Link
                    href={`https://instagram.com/${contacts.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GrInstagram />
                  </Nav.Link>
                </Nav>
              </Col>
            )}
          </Row>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// Prop Types
HeadBar.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      drop: PropTypes.array,
    })
  ),
  contacts: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
  }),
};

// Default Props
HeadBar.defaultProps = {
  title: "JustTalk",
  headerLinks: [],
  contacts: null,
};

export default HeadBar;