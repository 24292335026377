import React from 'react';
import "../../css/Buttons.css";
import {
Button
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiLogoAndroid } from "react-icons/bi";
import json from "../../Config";


class StoreButton extends React.Component{
  constructor(props) {
    super();
  this.state = json;

  }

  render(){

return (
      <>
      {/* <Button variant="dark" size='lg' as={Link} to={this.state.appstore}>
        <GrAppleAppStore/> Download on AppStore
      </Button>
      &nbsp;&nbsp; */}
      <Button variant="teal" size='lg' as={Link} to={this.state.gamma}>
        <BiLogoAndroid className='mb-1'/> Download for android
      </Button>
      </>
);

  }

};

export default StoreButton;
