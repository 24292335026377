// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-orange{
    background-color: rgb(219, 160, 33) !important;
    color: aliceblue !important;
}

.btn-teal{
    background-color:rgb(5, 145, 38) !important;
    color:white !important;
    border:none !important;
}

.btn-green{
    background-color:rgba(255, 255, 255, 0.911) !important;
    color:rgb(7, 99, 76) !important;
    border:none !important;
}
.btn-dark{
    background-color:rgba(0, 0, 0, 0.657) !important;
    color:#ffffff !important;
    border:none !important;
}`, "",{"version":3,"sources":["webpack://./src/css/Buttons.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,2BAA2B;AAC/B;;AAEA;IACI,2CAA2C;IAC3C,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,sDAAsD;IACtD,+BAA+B;IAC/B,sBAAsB;AAC1B;AACA;IACI,gDAAgD;IAChD,wBAAwB;IACxB,sBAAsB;AAC1B","sourcesContent":[".btn-orange{\n    background-color: rgb(219, 160, 33) !important;\n    color: aliceblue !important;\n}\n\n.btn-teal{\n    background-color:rgb(5, 145, 38) !important;\n    color:white !important;\n    border:none !important;\n}\n\n.btn-green{\n    background-color:rgba(255, 255, 255, 0.911) !important;\n    color:rgb(7, 99, 76) !important;\n    border:none !important;\n}\n.btn-dark{\n    background-color:rgba(0, 0, 0, 0.657) !important;\n    color:#ffffff !important;\n    border:none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
