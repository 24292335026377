import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import "../../css/Header.css";

const Foot = ({ bg, name }) => {
  return (
    <footer className={`${bg} footer-copyright text-center py-3 text-white`} style={{ zIndex: 1 }}>
      <Container fluid>
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <a href="/" className="footer-link">
          {name}
        </a>
      </Container>
    </footer>
  );
};

// PropTypes for type checking
Foot.propTypes = {
  bg: PropTypes.string,
  name: PropTypes.string.isRequired,
};

// Default props
Foot.defaultProps = {
  bg: "bg-light",
};

export default Foot;