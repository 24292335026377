// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 


@keyframes pulse {
    0% {
      background-color: #001F3F;
    }
    100% {
      background-color: #FF4136;
    }
  }


  @keyframes up {
    0% {
    opacity: 0;
    }
    10%, 90% {
    opacity: 1;
    }
    100% {
    opacity: 0;
    transform: translateY(-1024px);
    }
  }



  @keyframes wobble {
    33% {
    transform: translateX(-50px);
    }
    66% {
    transform: translateX(50px);
    } }



    @keyframes scroll {
         0%, 20% {
        transform: translateY(0) scaleY(1);
        }
        10% {
        opacity: 1;
        }
        100% {
        transform: translateY(36px) scaleY(2);
        opacity: 0.01;
        }
      }




      @keyframes slide-right {
        from {
           margin-left: -500px;
        }
    
        to {
           margin-left: 0%;
        }
     }

     @keyframes slide-up {
       from {
          margin-top: -500px;
       }
   
       to {
          margin-top: 0%;
       }
    }







@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:0;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:0;width:355px;}
} */
`, "",{"version":3,"sources":["webpack://./src/css/Animation.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAoGG","sourcesContent":["/* \n\n\n@keyframes pulse {\n    0% {\n      background-color: #001F3F;\n    }\n    100% {\n      background-color: #FF4136;\n    }\n  }\n\n\n  @keyframes up {\n    0% {\n    opacity: 0;\n    }\n    10%, 90% {\n    opacity: 1;\n    }\n    100% {\n    opacity: 0;\n    transform: translateY(-1024px);\n    }\n  }\n\n\n\n  @keyframes wobble {\n    33% {\n    transform: translateX(-50px);\n    }\n    66% {\n    transform: translateX(50px);\n    } }\n\n\n\n    @keyframes scroll {\n         0%, 20% {\n        transform: translateY(0) scaleY(1);\n        }\n        10% {\n        opacity: 1;\n        }\n        100% {\n        transform: translateY(36px) scaleY(2);\n        opacity: 0.01;\n        }\n      }\n\n\n\n\n      @keyframes slide-right {\n        from {\n           margin-left: -500px;\n        }\n    \n        to {\n           margin-left: 0%;\n        }\n     }\n\n     @keyframes slide-up {\n       from {\n          margin-top: -500px;\n       }\n   \n       to {\n          margin-top: 0%;\n       }\n    }\n\n\n\n\n\n\n\n@keyframes showup {\n    0% {opacity:0;}\n    20% {opacity:1;}\n    80% {opacity:1;}\n    100% {opacity:0;}\n}\n\n@keyframes slidein {\n    0% { margin-left:-800px; }\n    20% { margin-left:-800px; }\n    35% { margin-left:0px; }\n    100% { margin-left:0px; }\n}\n\n@keyframes reveal {\n    0% {opacity:0;width:0px;}\n    20% {opacity:1;width:0px;}\n    30% {width:355px;}\n    80% {opacity:1;}\n    100% {opacity:0;width:355px;}\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
